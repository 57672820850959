<style scoped>
  .call-out {
    position: relative;
    font-size: 1.8rem;
    font-weight: 500;
    font-style: oblique;
    color: var(--dark-purple);
    background: #ECECFF;
    border: 1px solid var(--light-purple);
    padding: calc(var(--base-unit) * 6);
    margin: 32px 0;
    padding-left: 48px;
    border-radius: var(--base-unit);
  }

  @media screen and (max-width: 768px) {
    .call-out {
      width: 90%;
      margin: 32px auto;
    }
  }

  .call-out:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -24px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: var(--dark-purple) url("data:image/svg+xml,%3Csvg width='28' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M2 0h24v24H2z'/%3E%3Cpath d='M19.007 11A3.987 3.987 0 0023 7c0-2.213-1.776-4-3.993-4A3.998 3.998 0 0015 7c0 2.213 1.79 4 4.007 4zm-10 0A3.987 3.987 0 0013 7c0-2.213-1.776-4-3.993-4A3.998 3.998 0 005 7c0 2.213 1.79 4 4.007 4zM9 13c-2.996 0-9 1.56-9 4.667v2C0 20.4.579 21 1.286 21h15.428c.707 0 1.286-.6 1.286-1.333v-2C18 14.56 11.996 13 9 13zm10.217 0a13.8 13.8 0 00-1.217.067c.025.013.038.04.05.053 1.43 1.107 2.422 2.587 2.422 4.547v2c0 .466-.088.92-.226 1.333h6.5c.69 0 1.254-.6 1.254-1.333v-2C28 14.56 22.14 13 19.217 13z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E") no-repeat center;
    box-shadow: 0 4px 5px -5px rgba(108,104,244,0.20), 0 8px 12px 2px rgba(108,104,244,0.15), 0 3px 15px 5px rgba(108,104,244,0.15);
  }

  .call-out--links a {
    display: block;
  }

  .call-out--link:before {
    top: 30px;
    background: var(--dark-purple) url("data:image/svg+xml,%3Csvg width='24' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.346 5.257C2.682 3.493 4.338 2.28 6.138 2.28h3.517c.624 0 1.14-.516 1.14-1.14 0-.624-.516-1.14-1.14-1.14H6.258C3.126 0 .33 2.292.03 5.413a5.995 5.995 0 005.965 6.589h3.66c.625 0 1.14-.516 1.14-1.14 0-.625-.515-1.14-1.14-1.14h-3.66a3.732 3.732 0 01-3.648-4.465zM8.394 7.2h7.201c.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2h-7.2c-.66 0-1.2.54-1.2 1.2 0 .66.54 1.2 1.2 1.2zM17.732 0h-3.397c-.624 0-1.14.516-1.14 1.14 0 .624.516 1.14 1.14 1.14h3.517c1.8 0 3.456 1.213 3.792 2.977a3.732 3.732 0 01-3.648 4.464h-3.66c-.625 0-1.14.516-1.14 1.14 0 .625.515 1.14 1.14 1.14h3.66a6.01 6.01 0 005.977-6.588C23.673 2.293 20.864 0 17.732 0z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat center;
  }

  .col-left--chart {
    grid-column: 1 / 7;
  }

  .col-right--chart {
    grid-column: 7 / 13;
  }

  b.text-dark-purple {
    font-size: 2.4rem;
  }

  ul.faux-cols {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }

  ul.faux-cols li {
    flex: 1;
    width: 30%;
    padding: 0 16px;
  }

  ul.faux-cols li:first-child {
    padding-left: 0;
  }

  ul.faux-cols li:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: 768px) {
    ul.faux-cols {
      display: block;
    }
    ul.faux-cols li {
      width: 100%;
      padding: 0;
    }
  }

</style>
<template>
  <v-stepper-content step="5" style="padding-bottom: 48px;">
    <v-card class="card simple-grid scroll">
      <div class="col-full">
        <h1>Results!</h1>
        <span class="sub-heading">Let's see what we can do for you, {{responses.contactInfo.companyName}}.</span>
        <h2 class="mt-6">Daily Reporting</h2>
        <p class="mt-6">
          Your team spends an average of <b data-cy="dataEntry" class="text-dark-purple">{{dataEntry}}</b> hours on duplicate data entry. This could be reduced by <b data-cy="dataEntrySaved" class="text-dark-purple">{{round(dataTimeSaved)}}</b> hours.
        </p>
        <div class="call-out">
          Teams that capture data in a centralized, cloud-based database save time on daily reporting responsibilities.
        </div>
        <p>Your team spends an average of <b data-cy="travelTime" class="text-dark-purple">{{travelTime}}</b> hours traveling from offsite to the office each week. This could be reduced by <b data-cy="travelTimeSaved" class="text-dark-purple">{{round(travelTimeSaved/travelTime) * 100}}%</b>.</p>
        <bar-chart class="mt-6 mb-6" chart-id="withoutAppia" :data="travelTimeData" chart-title="Document Delivery Time"></bar-chart>
      </div>
      <div class="col-left mt-6">
        <p>Teams that stay connected on one platform don’t need to travel to enter data - it’s captured live, in real-time, and reported back to the office.</p>
      </div>
      <div class="col-right mt-6">
        <p>See how the team at the <a href="https://www.infotechinc.com/images/appia_casestudy_meriden_2020_fnl.pdf" target="_blank">City of Meriden</a> implemented a construction administration and inspection platform to eliminate their cumbersome paper process and spend 75% more time in the field.</p>
      </div>
      <div class="col-full mt-6">
        <h2>Data Research</h2>
        <p>Your team spends an average of <b data-cy="searchTime" class="text-dark-purple">{{round(searchTime)}}</b> hours researching old files.</p>
        <p>You could save a total of <b data-cy="searchTimeSaved" class="text-dark-purple">{{round(searchTime * 0.95)}}</b> hours per project, leaving you with only <b data-cy="totalSearchTime" class="text-dark-purple">{{round(searchTime - searchTime * 0.95)}}</b> hours of research work per project.</p>
        <p>Missing documentation is a leading cause of disputes and delays. When all documentation is stored in one place, disputes are resolved quickly and audits are vastly simplified.</p>
        <p>See how the team at <a href="https://www.infotechinc.com/images/appia_casestudy_rsh_2020-3-1.pdf" target="_blank">RS&amp;H</a> used technology to quickly find files and resolve disputes on an aviation project.</p>
      </div>
      <div class="col-full mt-6">
        <h2>Overview</h2>
      </div>
      <div class="col-left--chart mt-6">
        <pie-chart type="pie" chart-id="allTime" :data="mainChart" chart-title="Current Time Allocation"></pie-chart>
      </div>
      <div class="col-right--chart mt-6">
        <pie-chart type="pie" chart-id="newTime" :data="mainChartNew" chart-title="Appia Time Allocation"></pie-chart>
      </div>
      <div class="col-full mt-6">
        <h2>Technology Usage</h2>
        <ul class="faux-cols mt-6">
          <li>
            <p>Your team <b class="text-dark-purple">does</b> <span v-if="!responses.mobile"> <b class="text-dark-purple">not</b> </span> use mobile devices to capture job site information.</p>
            <p>Mobile device use helps in many of the areas discussed above, from capturing accurate information at the source to reducing travel time.</p>
          </li>
          <li>
            <p>Your team <b class="text-dark-purple">does</b> <span v-if="!responses.real_time"> <b class="text-dark-purple">not</b> </span> encounter issues with a lack of real-time data when addressing problems.</p>
            <p>Real-time access to project reports as they are being inputted can allow project managers to determine what problems are occurring at any time.</p>
          </li>
          <li>
            <p>Your team <b class="text-dark-purple">does</b> <span v-if="!responses.document_errors"> <b class="text-dark-purple">not</b> </span> encounter issues with errors or omissions in project documentation.</p>
            <p>We often see errors and omissions when duplicate data entry is a factor. By capturing data once at the source, errors and omissions can be vastly reduced.</p>
          </li>
        </ul>
      </div>
      <div class="col-full mt-6">
        <p class="text-center mt-6">Here are a few more examples of teams using technology to solve their issues</p>
        <div class="call-out call-out--link">
          <a class="bigLink" href="https://www.infotechinc.com/images/appia_casestudy_wolfcreek_2019.pdf" target="_blank">Mobile Technology with <b>SSR</b></a><br>
          <a class="bigLink" href="https://www.infotechinc.com/images/appia_casestudy_fhu_2020_fnl.pdf" target="_blank">Construction Software Use with <b>FHU</b></a><br>
          <a class="bigLink" href="https://www.infotechinc.com/images/appia_casestudy_hancockcounty_2019.pdf" target="_blank">Real-Time Data Access with <b>Hancock County Ohio</b></a><br>
        </div>
      </div>
      <div class="col-full mt-6">
        <h2>Methodology</h2>
        <p class="mt-4" style="font-size:15px;">This report is based on a collection of findings based on industry studies, data from agency and consulting clients, and Department of Transportation (DOT) clients. The formulas used are calculated using self-reported data retrieved from these sources. While the data points in this report are not an extensive statistical sample, the results are based on high value, industry-specific insights.</p>
      </div>
    </v-card>
  </v-stepper-content>
</template>

<script>
import PieChart from './charts/PieChart.vue'
import BarChart from './charts/BarChart.vue'

export default {
  name: 'StepFive',
  components: {
    PieChart,
    BarChart
  },
  props: {
    responses: Object
  },
  computed: {
    empNum () {
      if (!this.responses) return
      return this.responses.contactInfo.numEmployees
    },
    dataEntry () {
      if (!this.empNum) return
      return this.empNum * this.responses.data_entry
    },
    dataTimeSaved () {
      return this.responses.data_entry > 3.9 ? this.empNum * 3.9 : 0
    },
    searchTime () {
      if (!this.empNum) return
      return this.empNum * this.responses.search_time
    },
    travelTime () {
      if (!this.empNum) return
      return this.empNum * this.responses.travel_time
    },
    travelTimeSaved () {
      return this.responses.travel_time > 2.5 ? this.empNum * 2.5 : this.travelTime
    },
    mainChartNew () {
      if (!this.responses) return
      const allTime = this.empNum * 40
      const leftoverTime = allTime - (this.dataEntry - this.empNum * 3.9) - (this.travelTime - this.travelTimeSaved) - (this.searchTime - this.searchTime * 0.95)
      return {
        datasets: [{
          label: 'No Appia',
          data: [this.dataEntry - this.empNum * 3.9, this.travelTime - this.travelTimeSaved, this.searchTime - this.searchTime * 0.95, leftoverTime],
          backgroundColor: ['#2D1E73', '#6C68F4', '#2C3E50', '#DBE5EC']
        }],
        labels: [
          'Data Entry',
          'Travel',
          'Search',
          'Work'
        ]
      }
    },
    mainChart () {
      if (!this.responses) return
      const allTime = this.empNum * 40
      const leftoverTime = allTime - this.dataEntry - this.travelTime - this.searchTime
      return {
        datasets: [{
          label: 'No Appia',
          data: [this.dataEntry, this.travelTime, this.searchTime, leftoverTime],
          backgroundColor: ['#2D1E73', '#6C68F4', '#2C3E50', '#DBE5EC']
        }],
        labels: [
          'Data Entry',
          'Travel',
          'Search',
          'Work'
        ]
      }
    },
    travelTimeData () {
      if (!this.responses) return
      return {
        datasets: [{
          data: [this.travelTime, this.travelTime - this.travelTimeSaved],
          backgroundColor: ['#2D1E73', '#6C68F4']
        }],
        labels: [
          'Without Appia',
          'With Appia'
        ]
      }
    },
    afterChartData () {
      if (!this.responses) return
      return {
        datasets: [{
          data: [40 * this.empNum - this.travelTimeSaved, this.travelTime - this.travelTimeSaved],
          backgroundColor: ['#2D1E73', '#6C68F4']
        }],
        labels: [
          'Office',
          'Travel'
        ]
      }
    }
  },
  methods: {
    round (num) {
      return Math.round(num * 10) / 10
    }
  }
}
</script>
