/* eslint-disable no-new */
<template>
  <div class="chart">
    <canvas :id="chartId" :data="data"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    data: Object,
    type: String,
    chartId: String,
    chartTitle: String
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    // eslint-disable-next-line no-new
    this.chart = new Chart(document.getElementById(this.chartId), {
      type: 'horizontalBar',
      data: this.data,
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.chartTitle
        },
        legend: {
          display: false,
          position: 'right'
        }
      }
    })
  },
  watch: {
    data (newValue, oldValue) {
      if (document.getElementById(this.chartId)) {
        this.chart.data = newValue
        this.chart.update()
      }
    }
  }
}
</script>
