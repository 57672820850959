<template>
  <v-stepper-content step="2">
    <v-card class="card simple-grid">
      <div class="col-full">
        <h2>
          Answer the following questions to the best of your knowledge
        </h2>
        <label class="mt-5">Do you or your team use mobile devices to capture job site data?</label>
        <v-radio-group v-model="responses.mobile">
          <v-radio data-cy="yes" label="Yes" :value="true" color="#6C68F4"></v-radio>
          <v-radio label="No" :value="false" color="#6C68F4"></v-radio>
        </v-radio-group>
        <label class="mt-5">How likely is a lack of readily available project data to cause issues or delays on a project?</label>
        <v-radio-group v-model="responses.real_time" :row="true">
          <v-radio label="Very unlikely" :value="0" color="#6C68F4"></v-radio>
          <v-radio label="Unlikely" :value="1" color="#6C68F4"></v-radio>
          <v-radio label="Neither likely or unlikely" :value="2" color="#6C68F4"></v-radio>
          <v-radio data-cy="likely" label="Likely" :value="3" color="#6C68F4"></v-radio>
          <v-radio label="Very likely" :value="4" color="#6C68F4"></v-radio>
        </v-radio-group>
        <label class="mt-5">How often does your team encounter errors or omissions in your construction project documents?</label>
        <v-radio-group v-model="responses.document_errors" :row="true">
          <v-radio label="No documents" :value="0" color="#6C68F4"></v-radio>
          <v-radio label="Few documents" :value="1" color="#6C68F4"></v-radio>
          <v-radio data-cy="half" label="About half" :value="2" color="#6C68F4"></v-radio>
          <v-radio label="Most documents" :value="3" color="#6C68F4"></v-radio>
          <v-radio label="All documents" :value="4" color="#6C68F4"></v-radio>
        </v-radio-group>
      </div>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  name: 'StepTwo',
  props: {
    responses: Object
  }
}
</script>
