<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-stepper-content step="3">
      <v-card class="card simple-grid">
        <div class="col-full">
          <h2>
            Work Week Estimates
          </h2>
          <label class="mt-5 mb-5">On average, how many hours in a week does a typical team member spend on data entry (entering data from paper into spreadsheets, from one spreadsheet to another, etc.)?</label>
          <v-text-field data-cy="data_entry" v-model.number="responses.data_entry" single-line type="number" color="#6C68F4" filled :rules="[rules.requiredText]"/>
          <label class="mt-5 mb-5">On average, how many hours in a week does a typical team member spend traveling from offsite to the office to submit daily inspection documentation?</label>
          <v-text-field data-cy="travel_time" v-model.number="responses.travel_time" single-line type="number" color="#6C68F4" filled :rules="[rules.requiredText]"/>
          <label class="mt-5 mb-5">On average, how many hours in a week does a typical team member spend researching or hunting down old files when closing out a project or reviewing work with a contractor?</label>
          <v-text-field data-cy="search_time" v-model.number="responses.search_time" single-line type="number" color="#6C68F4" filled :rules="[rules.requiredText]"/>
        </div>
      </v-card>
    </v-stepper-content>
  </v-form>
</template>

<script>
export default {
  name: 'StepThree',
  data: () => {
    return {
      valid: true,
      rules: {
        requiredText: value => !!value || 'Required'
      }
    }
  },
  props: {
    responses: Object
  },
  methods: {
    checkValidation () {
      this.valid = this.$refs.form.validate()
      if (this.valid === true) this.$emit('stepUp')
    }
  }
}
</script>
