<template>
  <v-form ref="form" lazy-validation v-model="valid">
    <v-stepper-content step="1" class="step-1" style="padding-bottom: 48px;">
      <v-card class="card simple-grid">
        <div class="col-full">
          <h1>
            Getting Started
            <span class="sub-heading">We’ll start simple.</span>
          </h1>
        </div>
        <div class="col-left">
          <label class="mt-5 mb-5">What’s your company name?</label>
          <v-text-field v-model="responses.contactInfo.companyName" color="#6C68F4" filled placeholder="Acme Inc." :rules="[rules.requiredText]"></v-text-field>
          <label class="mt-5 mb-5">What’s your email?</label>
          <v-text-field v-model="responses.contactInfo.email" color="#6C68F4" filled placeholder="example@acme.com" :rules="[rules.requiredEmail, rules.validEmail]"></v-text-field>
          <label class="mt-5 mb-5">What is your role at the company?</label>
          <v-radio-group v-model="responses.contactInfo.companyRole">
            <v-radio label="Construction Project Manager/Lead" :value="'construction_project_manager'" color="#6C68F4"></v-radio>
            <v-radio label="Lead Inspector" :value="'lead_inspector'" color="#6C68F4"></v-radio>
            <v-radio label="County Engineer" :value="'county_engineer'" color="#6C68F4"></v-radio>
            <v-radio label="Construction Services Manager" :value="'construction_services_manager'" color="#6C68F4"></v-radio>
            <v-radio label="Director of Public Works" :value="'director_of_public_works'" color="#6C68F4"></v-radio>
            <v-radio label="President/VP of Construction Management" :value="'president_of_construction_management'" color="#6C68F4"></v-radio>
            <v-radio label="Other" :value="'other'" color="#6C68F4"></v-radio>
            <v-text-field v-if="responses.contactInfo.companyRole == 'other'" placeholder="Other" color="#6C68F4" filled v-model="responses.contactInfo.companyOtherRole" :rules="[rules.requiredText]"></v-text-field>
          </v-radio-group>
        </div>
        <div class="col-right">
          <label class="mt-5 mb-5">In a typical year, how many projects does your company or organization manage?</label>
          <v-text-field v-model.number="responses.contactInfo.numProjects" type="number" color="#6C68F4" filled placeholder="Number of projects" :rules="[rules.requiredText]"></v-text-field>
          <label class="mt-5  mb-5">Across all projects, how many employees do you have on your team?</label>
          <v-text-field v-model.number="responses.contactInfo.numEmployees" type="number" color="#6C68F4" filled placeholder="Number of employees" :rules="[rules.requiredText]"></v-text-field>
          <label class="mt-5 mb-5">What role does your company fill in the construction process?</label>
          <v-radio-group v-model="responses.contactInfo.companyResponsibility">
            <v-radio label="Engineering Consultant" :value="'engineering_consultant'" color="#6C68F4"></v-radio>
            <v-radio label="Public Agency" :value="'public_agency'" color="#6C68F4"></v-radio>
            <v-radio label="Other" :value="'other'" color="#6C68F4"></v-radio>
            <v-text-field v-if="responses.contactInfo.companyResponsibility == 'other'" placeholder="Other" color="#6C68F4" filled v-model="responses.contactInfo.companyOtherResponsibility" :rules="[rules.requiredText]"></v-text-field>
          </v-radio-group>
          <label class="mt-5 mb-2">What is the typical project dollar range for your projects?</label>
          <v-radio-group v-model="responses.contactInfo.companyProjectCost">
            <v-radio label="$500k - $1 million" :value="'under_1_mil'" color="#6C68F4"></v-radio>
            <v-radio label="$1 million - $5 million" :value="'under_5_mil'" color="#6C68F4"></v-radio>
            <v-radio label="$5 million +" :value="'over_5_mil'" color="#6C68F4"></v-radio>
          </v-radio-group>
        </div>
      </v-card>
      <div class="mid-ground">
      </div>
    </v-stepper-content>
  </v-form>
</template>

<script>
export default {
  name: 'StepOne',
  data: () => {
    return {
      valid: true,
      rules: {
        requiredText: value => !!value || 'Required',
        requiredEmail: v => !!v || 'E-mail is required',
        validEmail: v => /.+@.+\.\w.+/.test(v) || 'E-mail must be valid'
      }
    }
  },
  props: {
    responses: Object
  },
  methods: {
    checkValidation () {
      this.valid = this.$refs.form.validate()
      if (this.valid === true) this.$emit('stepUp')
    }
  }
}
</script>
