import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'

Vue.use(Vuex)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    responses: {
      contactInfo: {}
    }
  },
  getters: {
    responses: state => {
      return state.responses
    }
  },
  mutations: {}
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
