<template>
  <v-stepper-content step="4">
    <v-card class="card">
      <div class="loading">
        <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#6C68F4">
          <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
            begin="0s" dur="0.8s"
            values="15;9;15" calcMode="linear"
            repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
            begin="0s" dur="0.8s"
            values="1;.5;1" calcMode="linear"
            repeatCount="indefinite" />
          </circle>
          <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9"
              begin="0s" dur="0.8s"
              values="9;15;9" calcMode="linear"
              repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
              begin="0s" dur="0.8s"
              values=".5;1;.5" calcMode="linear"
              repeatCount="indefinite" />
          </circle>
          <circle cx="105" cy="15" r="15">
              <animate attributeName="r" from="15" to="15"
                begin="0s" dur="0.8s"
                values="15;9;15" calcMode="linear"
                repeatCount="indefinite" />
              <animate attributeName="fill-opacity" from="1" to="1"
                begin="0s" dur="0.8s"
                values="1;.5;1" calcMode="linear"
                repeatCount="indefinite" />
          </circle>
        </svg>
        <label class="mt-5">Hang tight while we crunch some numbers...</label>
      </div>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  name: 'StepFour',
  props: {
    responses: Object
  }
}
</script>
<style scoped>

  .loading {
    margin-top: 25%;
    text-align: center;
  }

</style>
